import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { HeaderAllMenuItemLink } from "~/components/header/header-oneline-menu-container/header-all-menu/common/header-mega-menu-item-link";

export const Title = styled.div`
  color: #001246;
  font-weight: 700;
  font-size: 16px;
  font-family: ${fontRobotoCondensed};
  line-height: 19px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;

  padding: 12px;

  column-gap: 16px;

  background: rgba(69, 133, 255, 0.2);
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-flow: row;
  gap: 16px;
  ${HeaderAllMenuItemLink}:not(:last-child):after {
    content: " | ";

    margin-inline-start: 16px;
  }
`;
