import styled from "@emotion/styled";

export const ItemContainer = styled.div``;

export const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 28px 32px;

  max-block-size: 500px;

  ${ItemContainer}:first-child {
    ${props => props.theme.breakpoints.up("mediumDesktop")} {
      block-size: 100%;
    }
  }

  ${props => props.theme.breakpoints.up("mediumDesktop")} {
    max-block-size: 420px;
  }
`;
