import type { FunctionComponent } from "react";
import type { PreloadedQuery } from "react-relay";
import { graphql, useFragment, usePreloadedQuery } from "react-relay";
import "swiper/css";
import "swiper/css/navigation";

import { query } from "~/components/header/header-oneline-menu-container";
import { useOnelineMenuTracking } from "~/components/header/header-oneline-menu-container/hooks";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import { useEditionValue } from "~/lib/edition";
import { useCurrentPageType } from "~/lib/router/hooks";
import type { headerOnelineMenuAppConfigQuery$key } from "~/queries/__generated__/headerOnelineMenuAppConfigQuery.graphql";
import type { headerOnelineMenuContainerQuery } from "~/queries/__generated__/headerOnelineMenuContainerQuery.graphql";

import {
  Container,
  MenuItem,
  StyledBaseEntityLink,
  StyledSwiper,
  StyledSwiperSlide,
} from "./styles";

export type Props = {
  queryReference: PreloadedQuery<headerOnelineMenuContainerQuery>;
};

export const HeaderOnelineMenu: FunctionComponent<Props> = ({ queryReference }) => {
  const data_ = usePreloadedQuery(query, queryReference);

  const data = useFragment<headerOnelineMenuAppConfigQuery$key>(
    graphql`
      fragment headerOnelineMenuAppConfigQuery on Query {
        onelineMenu: appConfig(filter: { entityId: "scmp_pwa_oneline_menu" }) {
          json
        }
      }
    `,
    data_,
  );

  const onelineMenuAppConfig = data?.onelineMenu?.json as ScmpPwaOnelineMenuAppConfig;
  const edition = useEditionValue({ initializeWithValue: true });
  const menuItems = onelineMenuAppConfig?.list[edition];

  const { edition: trackingEdition } = useOnelineMenuTracking();
  const currentPageType = useCurrentPageType();

  if (!onelineMenuAppConfig || !menuItems) return null;

  return (
    <Container>
      <StyledSwiper cssMode={false} pagination={false} slidesPerView="auto">
        {menuItems?.map((item, index) => (
          <StyledSwiperSlide key={index}>
            <StyledBaseEntityLink
              linkEntity={item}
              query={{
                module: `oneline_menu_section_${edition}`,
                pgtype: currentPageType,
              }}
              onClick={() => {
                sendGATracking({
                  action: "Linemenu/Click",
                  category: "Menu",
                  label: `${trackingEdition}/Linemenu/Click/${item.trackingName ?? item.name}`,
                });
              }}
            >
              <MenuItem>{item.name}</MenuItem>
            </StyledBaseEntityLink>
          </StyledSwiperSlide>
        ))}
      </StyledSwiper>
    </Container>
  );
};

HeaderOnelineMenu.displayName = "HeaderOnelineMenu";
