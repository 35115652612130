import type { FunctionComponent } from "react";

import {
  HeaderAllMenuItemLinkContainer,
  HeaderAllMenuTitle,
} from "~/components/header/header-oneline-menu-container/header-all-menu/common";
import { useOnelineMenuTracking } from "~/components/header/header-oneline-menu-container/hooks";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";

import { Container, StyledLink } from "./styles";

export type Props = {
  className?: string;
};

export const HeaderAllMenuEpaper: FunctionComponent<Props> = ({ className }) => {
  const { edition } = useOnelineMenuTracking();

  return (
    <Container className={className}>
      <HeaderAllMenuTitle>ePaper</HeaderAllMenuTitle>
      <HeaderAllMenuItemLinkContainer>
        {/* update this when we can move ePaper items to drupal config
            in that case HeaderAllMenuItemLink should be used */}
        <StyledLink
          pathname="/epaper"
          target="_blank"
          onClick={() => {
            sendGATracking({
              action: "Linemenu/Click",
              category: "Menu",
              label: `${edition}/Linemenu/All/Epaper/Epaper`,
            });
          }}
        >
          ePaper
        </StyledLink>
      </HeaderAllMenuItemLinkContainer>
    </Container>
  );
};

HeaderAllMenuEpaper.displayName = "HeaderAllMenuEpaper";
