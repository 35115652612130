import styled from "@emotion/styled";

import { EntityLink } from "~/components/entity-link";
import { SectionStyleHeaderMenuItem } from "~/components/section/section-style/section-style-header/section-style-header-menu-item";

export const StyledEntityLink = styled(EntityLink)`
  padding-inline-start: 24px;

  white-space: nowrap;
  text-transform: uppercase;
`;
export const StyledSectionStyleHeaderMenuItem = styled(SectionStyleHeaderMenuItem)``;

export const Container = styled.div`
  display: flex;
  align-items: center;

  padding-inline-end: 20px;

  ${StyledEntityLink}:first-child {
    padding-inline-start: 0;
  }

  ${StyledEntityLink} {
    padding-inline-start: 24px;
  }

  /** Hide the items to prevent overflow */
  ${props => props.theme.breakpoints.down("largeDesktop")} {
    ${StyledEntityLink}:nth-child(n+6) {
      display: none;
    }
  }
  ${StyledEntityLink}:nth-child(n+7) {
    display: none;
  }
`;
