import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";

import { BaseEntityLink } from "~/components/base-entity-link";
import { EntityLink } from "~/components/entity-link";

export const HeaderAllMenuItemLinkCss = css`
  color: #333333;
  font-size: 15px;
  font-family: ${fontRoboto};
  line-height: 18px;

  &:hover {
    color: #2c4692;
    text-decoration: underline;
  }
`;

export const HeaderAllMenuItemLink = styled(EntityLink)`
  ${HeaderAllMenuItemLinkCss}
`;

export const HeaderAllMenuItemBaseLink = styled(BaseEntityLink)`
  ${HeaderAllMenuItemLinkCss}
`;
