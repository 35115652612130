export const application = {
  artifacts: {
    applicationId: "artifacts",
    entityId: "artifacts",
    name: "Artifacts",
    urlAlias: "https://artifact.scmp.com/",
  },
  chinaPowerPlayers: {
    applicationId: "china-power-players",
    entityId: "china-power-players",
    name: "China Power Players",
    urlAlias: "https://sc.mp/china-power-players",
  },
  scmpEvents: {
    applicationId: "scmp-events",
    entityId: "scmp-events",
    name: "SCMP Events",
    urlAlias: "http://www.scmpevents.com",
  },
  scmpResearch: {
    applicationId: "scmp-research",
    entityId: "scmp-research",
    name: "SCMP Research",
    urlAlias: "https://research.scmp.com",
  },
  theStore: {
    applicationId: "the-store",
    entityId: "the-store",
    name: "SCMP Store",
    urlAlias: "https://store.scmp.com",
  },
  yp: {
    applicationId: "yp",
    entityId: "yp",
    name: "YP",
    urlAlias: "https://www.scmp.com/yp",
  },
};
