import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as BaseEntityLinkProps } from "~/components/base-entity-link";
import {
  HeaderAllMenuItemBaseLink,
  HeaderAllMenuItemLinkContainer,
  HeaderAllMenuTitle,
} from "~/components/header/header-oneline-menu-container/header-all-menu/common";
import { useOnelineMenuTracking } from "~/components/header/header-oneline-menu-container/hooks";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { headerAllMenuSectionAppConfigQuery$key } from "~/queries/__generated__/headerAllMenuSectionAppConfigQuery.graphql";

import { Container, ItemContainer } from "./styles";

export type Props = {
  className?: string;
  queryParameter: BaseEntityLinkProps["query"];
  reference: headerAllMenuSectionAppConfigQuery$key;
};

export const HeaderAllMenuSection: FunctionComponent<Props> = ({
  queryParameter,
  reference: reference_,
}) => {
  const appConfig = useFragment(
    graphql`
      fragment headerAllMenuSectionAppConfigQuery on Query {
        onelineMenu: appConfig(filter: { entityId: "scmp_pwa_oneline_menu" }) {
          json
        }
      }
    `,
    reference_,
  );

  const onelineMenuAppConfig = appConfig?.onelineMenu?.json as ScmpPwaOnelineMenuAppConfig;
  const allList = onelineMenuAppConfig?.all;
  const { edition } = useOnelineMenuTracking();
  if (!allList) return null;

  return (
    <Container>
      {allList.map((listItem, index) => (
        <ItemContainer key={index}>
          <HeaderAllMenuTitle>{listItem.name}</HeaderAllMenuTitle>
          <HeaderAllMenuItemLinkContainer>
            {listItem.items.map((link, index) => (
              <HeaderAllMenuItemBaseLink
                key={index}
                linkEntity={link}
                query={queryParameter}
                onClick={() => {
                  sendGATracking({
                    action: "Linemenu/Click",
                    category: "Menu",
                    label: `${edition}/Linemenu/All/${listItem.name}/${link.name}`,
                  });
                }}
              >
                {link.name}
              </HeaderAllMenuItemBaseLink>
            ))}
          </HeaderAllMenuItemLinkContainer>
        </ItemContainer>
      ))}
    </Container>
  );
};

HeaderAllMenuSection.displayName = "HeaderAllMenuSection";
