import type { FunctionComponent } from "react";

import type { Props as BaseEntityLinkProps } from "~/components/base-entity-link";
import { useOnelineMenuTracking } from "~/components/header/header-oneline-menu-container/hooks";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import { application, section } from "~/data";

import LogoChinaPower from "./logo-china-power.svg";
import LogoScmpEvents from "./logo-scmp-events.svg";
import LogoScmpResearch from "./logo-scmp-research.svg";
import LogoStyle from "./logo-style.svg";
import LogoTheStore from "./logo-the-store.svg";
import LogoYoungPost from "./logo-young-post.svg";
import { Container, StyledBaseEntityLink } from "./styles";

export type Props = {
  className?: string;
  queryParameter: BaseEntityLinkProps["query"];
};

const logoList = [
  {
    component: LogoChinaPower,
    linkEntity: application.chinaPowerPlayers,
    tracking: "China's Power Players",
  },
  {
    component: LogoStyle,
    linkEntity: section.style,
    tracking: "Style",
  },
  {
    component: LogoYoungPost,
    linkEntity: application.yp,
    tracking: "Young Post",
  },
  {
    component: LogoScmpResearch,
    linkEntity: application.scmpResearch,
    tracking: "Research",
  },
  {
    component: LogoTheStore,
    linkEntity: application.theStore,
    tracking: "The Store",
  },
  {
    component: LogoScmpEvents,
    linkEntity: application.scmpEvents,
    tracking: "SCMP Events",
  },
];

export const HeaderAllMenuApplications: FunctionComponent<Props> = ({
  className,
  queryParameter,
}) => {
  const { edition } = useOnelineMenuTracking();
  return (
    <Container className={className}>
      {logoList.map((logo, index) => (
        <StyledBaseEntityLink
          key={index}
          linkEntity={logo.linkEntity}
          query={queryParameter}
          onClick={() => {
            sendGATracking({
              action: "Linemenu/Click",
              category: "Menu",
              label: `${edition}/Linemenu/All/Logo/${logo.tracking}`,
            });
          }}
        >
          <logo.component />
        </StyledBaseEntityLink>
      ))}
    </Container>
  );
};

HeaderAllMenuApplications.displayName = "HeaderAllMenuApplications";
