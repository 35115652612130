import capitalize from "lodash/capitalize";

import { useEditionValue } from "~/lib/edition";

export const useOnelineMenuTracking = () => {
  const edition = useEditionValue({ initializeWithValue: true });

  return {
    edition: capitalize(edition),
  };
};
