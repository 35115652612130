import styled from "@emotion/styled";

import {
  HeaderAllMenuItemBaseLink,
  HeaderAllMenuItemLink,
} from "~/components/header/header-oneline-menu-container/header-all-menu/common/header-mega-menu-item-link";

export const HeaderAllMenuItemLinkContainer = styled.div`
  display: flex;
  flex-flow: column;

  ${HeaderAllMenuItemLink}:not(:last-child),${HeaderAllMenuItemBaseLink}:not(:last-child) {
    margin-block-end: 14px;
  }
`;
