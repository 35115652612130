import type { PopperProps } from "@mui/base/Popper";
import { Popper as BasePopper } from "@mui/base/Popper";
import { useSyncedRef } from "@react-hookz/web";
import type { PopupState } from "material-ui-popup-state/hooks";
import {
  bindHover,
  bindPopover,
  bindPopper,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useRouter } from "next/router";
import type { FunctionComponent, ReactElement } from "react";
import { cloneElement, useEffect, useMemo } from "react";

export type Props = {
  bindType?: "hover" | "toggle";
  className?: string;
  popupComponent?: PopperProps["children"];
  popupId?: string;
  popupState?: PopupState;
  triggerComponent: ReactElement;
} & Pick<
  PopperProps,
  "disablePortal" | "keepMounted" | "modifiers" | "placement" | "container" | "transition"
>;

export const Popper: FunctionComponent<Props> = ({
  bindType = "hover",
  className,
  container,
  disablePortal = false,
  keepMounted = false,
  modifiers = [],
  placement = "bottom-start",
  popupComponent,
  popupId = "tooltip",
  popupState: popupState_,
  transition,
  triggerComponent,
}) => {
  const defaultPopupState = usePopupState({ popupId, variant: "popper" });

  const popupState = useMemo(
    () => popupState_ ?? defaultPopupState,
    [popupState_, defaultPopupState],
  );
  // Prevent the tooltip keep showing when route change
  const latestPopupState = useSyncedRef(popupState);
  const { events } = useRouter();
  useEffect(() => {
    const handler = () => {
      latestPopupState.current.close();
    };
    events.on("routeChangeComplete", handler);
    return () => {
      events.off("routeChangeComplete", handler);
    };
  }, [events, latestPopupState]);

  const filteredBindPopover = (popupState: PopupState) => {
    const { anchorEl, id, onMouseLeave, open } = bindPopover(popupState);
    const filteredProps: PopperProps = { anchorEl, id, onMouseLeave, open };
    return filteredProps;
  };

  const bindMap = {
    hover: {
      state: bindPopper(popupState),
      trigger: bindHover(popupState),
    },
    toggle: {
      state: filteredBindPopover(popupState),
      trigger: bindToggle(popupState),
    },
  };

  return (
    <>
      {cloneElement(triggerComponent, bindMap[bindType].trigger)}
      {popupComponent !== null && (
        <BasePopper
          {...bindMap[bindType].state}
          className={className}
          container={container}
          disablePortal={disablePortal}
          keepMounted={keepMounted}
          modifiers={modifiers}
          placement={placement}
          transition={transition}
        >
          {popupComponent}
        </BasePopper>
      )}
    </>
  );
};

Popper.displayName = "Popper";
