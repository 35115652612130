import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as BaseEntityLinkProps } from "~/components/base-entity-link";
import {
  HeaderAllMenuItemLink,
  HeaderAllMenuItemLinkContainer,
  HeaderAllMenuTitle,
} from "~/components/header/header-oneline-menu-container/header-all-menu/common";
import { useOnelineMenuTracking } from "~/components/header/header-oneline-menu-container/hooks";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { headerAllMenuFocusQuery$key } from "~/queries/__generated__/headerAllMenuFocusQuery.graphql";

import { Container } from "./styles";

export type Props = {
  className?: string;
  queryParameter: BaseEntityLinkProps["query"];
  reference: headerAllMenuFocusQuery$key;
};

export const HeaderAllMenuFocus: FunctionComponent<Props> = ({
  className,
  queryParameter,
  reference: reference_,
}) => {
  const { edition } = useOnelineMenuTracking();
  const queue = useFragment(
    graphql`
      fragment headerAllMenuFocusQuery on Query {
        focusQueue: queue(filter: { name: "all_menu_focus" }) {
          items(first: 2) {
            edges {
              node {
                ... on Topic {
                  ...entityLink
                  name
                }
                ... on Section {
                  ...entityLink
                  name
                }
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  return (
    <Container className={className}>
      <HeaderAllMenuTitle>Focus</HeaderAllMenuTitle>
      <HeaderAllMenuItemLinkContainer>
        {queue.focusQueue?.items?.edges.map((edge, index) => (
          <HeaderAllMenuItemLink
            key={index}
            query={queryParameter}
            reference={edge.node}
            onClick={() => {
              sendGATracking({
                action: "Linemenu/Click",
                category: "Menu",
                label: `${edition}/Linemenu/All/Focus/${edge.node.name ?? ""}`,
              });
            }}
          >
            {edge.node.name}
          </HeaderAllMenuItemLink>
        ))}
      </HeaderAllMenuItemLinkContainer>
    </Container>
  );
};

HeaderAllMenuFocus.displayName = "HeaderAllMenuFocus";
