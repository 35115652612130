import type { Modifier } from "@popperjs/core";

import { isNumeric } from "~/lib/utils";

// Used to size the popper in full width relative to body and place it in center horizontally
export const centerAlignModifier: Modifier<"centerAlign", object> = {
  enabled: true,
  fn({ state }) {
    state.styles.popper.inlineSize = "100%";
    const popperYOffset = state?.modifiersData?.popperOffsets?.y;
    const popperXOffset = state?.modifiersData?.popperOffsets?.x;
    const arrowXOffset = state?.modifiersData?.arrow?.x;
    const arrowYOffset = state?.modifiersData?.arrow?.centerOffset;
    if (isNumeric(popperYOffset)) {
      state.styles.popper.transform = `translate3d(0, ${popperYOffset}px, 0)`;
    }
    if (isNumeric(arrowXOffset) && isNumeric(arrowYOffset) && isNumeric(popperXOffset)) {
      state.styles.arrow.transform = `translate3d(${
        arrowXOffset + popperXOffset
      }px, ${arrowYOffset}px, 0)`;
    }
  },
  name: "centerAlign",
  phase: "beforeWrite",
  requires: ["computeStyles"],
};
